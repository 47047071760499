<template>
  <div class="container-fluid px-0 mx-0">
    <div class="bg-img-pres">
      <p class="title text-white div-a2 custome-styles" data-aos="fade-up" style="overflow-wrap: break-word" id="titles">
        {{ textos_pre.text_presupuesto }}
      </p>
    </div>
    <div id="titles">
      <div class="container text-left my-5">
        <b-alert :show="alert.show" :variant="alert.variant" dismissible @dismissed="alert.show = false">
          {{ alert.message }}
        </b-alert>
        <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">
          <div class="row">
            <div class="col-md-12">
              <b-form-group id="input-group-1" :label="textos_pre.text_Empresa">
                <b-form-input id="input-1" v-model="form.cliente" type="text" :placeholder="textos_pre.text_Empresa" required></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group id="input-group-1" :label="textos_pre.text_Nombre">
                <b-form-input id="input-1" v-model="form.n_contacto" type="text" :placeholder="textos_pre.text_Nombre" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group id="input-group-1" :label="textos_pre.text_Apellido">
                <b-form-input id="input-1" v-model="form.a_contacto" type="text" :placeholder="textos_pre.text_Apellido" required></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group id="input-group-1" :label="textos_pre.text_Ciudad">
                <b-form-input id="input-1" v-model="form.provincia" type="text" :placeholder="textos_pre.text_Ciudad" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group id="input-group-1" :label="textos_pre.text_codigo_postal">
                <b-form-input id="input-1" v-model="form.codpost" type="number" :placeholder="textos_pre.text_codigo_postal" required></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group id="input-group-1" :label="textos_pre.text_poblacion">
                <b-form-input id="input-1" v-model="form.poblacion" type="text" :placeholder="textos_pre.text_poblacion" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group id="input-group-1" :label="textos_pre.text_calle">
                <b-form-input id="input-1" v-model="form.dir1" type="text" :placeholder="textos_pre.text_calle" required></b-form-input>
              </b-form-group>
            </div>
          </div>

          <b-form-group :label="textos_pre.text_Tipologia" v-slot="{ ariaDescribedby }">
            <b-form-radio-group id="radio-group-2" v-model="form.tipocasa" :aria-describedby="ariaDescribedby" name="radio-sub-component">
              <div class="row">
                <div class="col-md-2">
                  <b-form-radio class="text-center" value="Casa">{{ textos_pre.text_casa }}</b-form-radio>
                </div>
                <div class="col-md-2">
                  <b-form-radio class="text-center" value="Apartamento">{{ textos_pre.text_apartamento }}</b-form-radio>
                </div>
                <div class="col-md-2">
                  <b-form-radio class="text-center" value="Piscina">{{ textos_pre.text_piscina }}</b-form-radio>
                </div>
              </div>
            </b-form-radio-group>
          </b-form-group>
          <div class="row">
            <div class="col-md-6">
              <b-form-group id="input-group-1" :label="textos_pre.text_numero_personas">
                <b-form-input id="input-1" v-model="form.numpersonas" type="number" :placeholder="textos_pre.text_numero_personas" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group id="input-group-1" :label="textos_pre.text_estancia">
                <b-form-input id="input-1" v-model="form.estancia" type="text" :placeholder="textos_pre.text_estancia" required></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group id="input-group-1" :label="textos_pre.text_Telefono">
                <b-form-input id="input-1" v-model="form.telefono" type="text" :placeholder="textos_pre.text_Telefono" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group id="input-group-1" :label="textos_pre.text_Email">
                <b-form-input id="input-1" v-model="form.email" type="email" :placeholder="textos_pre.text_Email" required></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div>
            <b-form-group id="input-group-1" :label="textos_pre.text_Peticion_presupuesto">
              <b-form-textarea id="textarea-rows" v-model="form.presupuesto" :placeholder="textos_pre.text_Peticion_presupuesto" rows="6"></b-form-textarea>
            </b-form-group>
          </div>
          <div class="text-right">
            <button type="submit" class="btn btn-blue_linetor px-5 pb-2" style="filter: drop-shadow(rgba(0, 0, 0, 0.7) 1px 1px 4px); border-radius: 50px;">
              {{ textos_pre.text_Button_enviar }}
            </button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: "first",
      options: [
        { text: "Toggle this custom radio", value: "first" },
        { text: "Or toggle this other custom radio", value: "second" },
        { text: "This one is Disabled", value: "three " },
      ],
      textos: {},
      pais: null,
      paises: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      idioma: null,
      idiomas: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      textos_pre: {
        // text_Empresa: 'Empresa',
        // text_Nombre: 'Nombre',
        // text_Apellido: 'Apellido',
        // text_Ciudad: 'Ciudad',
        // text_Telefono:'Telefono',
        // text_Email:'E-mail',
        // text_Peticion_presupuesto: 'Petición de presupuesto',
        // text_Button_ver_mas: 'Enviar',
      },
      form: {},
      show: true,
      alert: {
        show: false,
        message: '',
        variant: 'success'
      }
    };
  },
  async mounted() {
    this.$store.watch(
      (state) => [state.recarga].join(),
      async () => {
        // this.showItemsNumber();
        // this.refreshButton();
        this.textos = JSON.parse(localStorage.getItem("text"));
        await this.llenarCampos();
        console.log(this.textos);
      }
    );
    this.textos = JSON.parse(localStorage.getItem("text"));
    await this.llenarCampos();
  },
  methods: {
    async onSubmit() {
      try {
        // Construir el valor de "demanda" combinando los campos necesarios
        const demanda = `PRESUPUESTO DESDE LINETOR.NET: TIPOLOGÍA: "${this.form.tipocasa}", NUMERO DE PERSONAS: ${this.form.numpersonas}, ESTANCIA: ${this.form.estancia}, DETALLES: ${this.form.presupuesto}`;

        // Asignar "demanda" al formulario y eliminar las propiedades que no quieres enviar directamente
        this.form.medio = "PA-WEB";
        this.form.demanda = demanda;
        delete this.form.numpersonas;
        delete this.form.estancia;
        delete this.form.presupuesto;
        delete this.form.tipocasa;

        // Enviar el formulario modificado al backend
        const response = await this.$store.dispatch("post", {
          path: "nuevoPresupuestoWebLinetor",
          data: this.form,
        });
        console.log("Formulario enviado exitosamente:", response);
        this.alert.message = 'Formulario enviado exitosamente';
        this.alert.variant = 'success';
        alert('Formulario enviado exitosamente');
            } catch (error) {
        console.error("Error al enviar el formulario:", error);
        this.alert.message = 'Hubo un error al enviar el formulario';
        this.alert.variant = 'danger';
        alert('Hubo un error al enviar el formulario');
            }

      this.alert.show = true;
    },
    onReset() {},
    async llenarCampos() {
      this.textos_pre = {
        text_Empresa: this.textos.text_Empresa,
        text_Nombre: this.textos.text_Nombre,
        text_Apellido: this.textos.text_Apellido,
        text_Ciudad: this.textos.text_Ciudad,
        text_Telefono: this.textos.text_Telefono,
        text_Email: this.textos.text_Email,
        text_Peticion_presupuesto: this.textos.text_Peticion_presupuesto,
        text_Button_enviar: this.textos.text_Button_enviar,
        text_presupuesto: this.textos.text_presupuesto,
        text_codigo_postal: this.textos.text_codigo_postal,
        text_poblacion: this.textos.text_poblacion,
        text_calle: this.textos.text_calle,
        text_Tipologia: this.textos.text_Tipologia,
        text_casa: this.textos.text_casa,
        text_apartamento: this.textos.text_apartamento,
        text_piscina: this.textos.text_piscina,
        text_numero_personas: this.textos.text_numero_personas,
        text_estancia: this.textos.text_estancia,
      };
      console.log("llenando campos en presupuesto mounted", this.textos_pre);
    },
  },
};
</script>

<style>
.bg-img-pres {
  /* The image used */
  background-image: url("/img/img-bg-pre.png");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Fonts Styles */
#titles {
  font-family: 'Merriweather', serif !important;
}

#subtitles{
  font-family: 'Sk-Modernist', sans-serif !important;
}
/* Fonts Styles */

/* Sombreado a titulos Styles */
.custome-styles{
  text-shadow: 6px 3px 8px rgba(0, 0, 0, 0.8);
}

</style>